import React from 'react';
import Rotas from './routes';
import './services/i18n';


const App = () => {
    return (
        <>
            <Rotas />
        </>
    );
};

export default App;
