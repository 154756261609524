// Duvidas.js
import React from 'react';
import { FaEnvelope } from 'react-icons/fa';
import Header from '../../components/siderbar';
import './styles.css';
import { useTranslation } from 'react-i18next';

import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/duvidas.png'

function Duvidas() {
    const { t } = useTranslation();

    return (
        <Header>

            <InfoCard title={t("questions_suggestions")} icon={questionIcon} />

            <div className="duvidas-container">
                <div className='content-bg'>
                    <h2 className="duvidas-titulo">{t("questions_title")}</h2>
                    <p className="duvidas-texto">
                        {t("questions_text")}
                    </p>
                    <div className="duvidas-email-container">
                        <FaEnvelope className="email-icon" />
                        <p className="duvidas-email">mchatrf@gmail.com</p>
                    </div>
                </div>


            </div>
        </Header>
    );
}

export default Duvidas;
