// InstitutoAutismo.js
import React from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import tutorial from "../../assets/tutorial2.png";
import { useTranslation } from 'react-i18next';

import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/tutorial.png'


function TutotialMchat() {
    const { t } = useTranslation();

    return (
        <Header>
            <InfoCard title={t("tutorial_mchatrf")} icon={questionIcon} />

            <div className="tutorial-container">
                <div className="tutorial-card">
                    <div className="tutorial-header">
                        <img src={tutorial} alt={t("process_icon_alt")} className="sidebar-icon-2" />
                        <h2>{t("usage_instructions_title")}</h2>
                    </div>
                    <p className="tutorial-text">{t("usage_instructions_text_1")}</p>
                    <p className="tutorial-text">{t("usage_instructions_text_2")}</p>
                    <p className="tutorial-text">{t("usage_instructions_text_3")}</p>
                    <p className="tutorial-text">{t("usage_instructions_text_4")}</p>
                </div>
            </div>

   
        </Header>
    );
}

export default TutotialMchat;
