import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import Header from '../../components/siderbar';
import './styles.css';
import { useLocation } from 'react-router-dom';
import { getAuthHeaders } from '../../services/authHeaders';
import perguntas from '../../services/perguntasMchat';

import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/m-chat-rf.png'
import { useTranslation } from 'react-i18next';


function DetalhesTEA() {
    const location = useLocation();
    const [teaData, setTeaData] = useState(null);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const { i18n, t } = useTranslation();
    const language = i18n.language; // Obtenha o idioma atual

    const queryParams = new URLSearchParams(location.search);
    const id_usuario = queryParams.get('id_usuario');
    const id_tea = queryParams.get('id_tea');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const fetchTeaDetails = async () => {
            try {
                const response = await fetch(`https://apiprojetotea.squareweb.app/obter_tea?id_usuario=${id_usuario}&id_tea=${id_tea}`, {
                    method: 'GET',
                    headers: getAuthHeaders(),
                });

                if (response.ok) {
                    const data = await response.json();
                    setTeaData(data.tea);
                } else {
                    console.error('Erro ao buscar os detalhes do TEA');
                }
            } catch (error) {
                console.error('Erro ao conectar com o servidor', error);
            }
        };

        if (id_usuario && id_tea) {
            fetchTeaDetails();
        }
    }, [id_usuario, id_tea]);

   

    const respostasValores = teaData && teaData.respostas ? teaData.respostas.map(respostaObj => {
        const resposta = Object.values(respostaObj)[0].toLowerCase();
        
        if (language === 'en') {
            return resposta === 'sim' ? 'Yes' : 'No';
        } else {
            return resposta === 'sim' ? 'Sim' : 'Não';
        }
    }) : [];
    
    console.log("Respostas valores ", respostasValores);

    
    // Mapeie o idioma para a chave correta do JSON
    const questionKey = language === 'pt_br' ? 'questions_br' :
        language === 'pt_pt' ? 'questions_pt' : 'questions_en';

    const openModal = (index) => {
        setSelectedQuestionIndex(index);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedQuestionIndex(null);
    };

    const generatePDF = async () => {
        if (!teaData) return;

        const doc = new jsPDF({ format: 'a4' });
        const pageWidth = 180;
        let yPosition = 10;

        // Título Principal
        doc.setFontSize(22);
        doc.setFont("helvetica", "bold");
        doc.text(t("checklist"), 10, yPosition, { maxWidth: pageWidth });
        yPosition += 15;

        // Informações Gerais
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        yPosition += 10;

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        const details = [
            `${t("child_name")}: ${teaData.nome_crianca}`,
            `${t("responsible")}: ${teaData.responsavel}`,
            `${t("creation_date")}: ${new Date(teaData.data_nascimento).toLocaleDateString()}`,
            `${t("address")}: ${teaData.endereco}`,
            `${t("city")}: ${teaData.cidade}`,
            `${t("contact_number")}: ${teaData.numero_contato}`
        ];
        details.forEach(detail => {
            doc.text(detail, 10, yPosition);
            yPosition += 8;
        });

        yPosition += 10;

        // Pontuação e Nível de Risco
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text(t("score_reached"), 10, yPosition);
        yPosition += 10;

        doc.setFontSize(14);
        doc.setFont("helvetica", "normal");
        doc.text(`${teaData.pontuacao} ${t("points")}`, 10, yPosition);
        yPosition += 10;

        const riskLevel = teaData.pontuacao <= 2 ? t("low_risk_level") :
            teaData.pontuacao <= 7 ? t("moderate_risk_level") :
                t("high_risk_level");
        doc.text(riskLevel, 10, yPosition);

        yPosition += 15;

        // Orientações Baseadas na Pontuação
        let instructions = teaData.pontuacao <= 2 ? t("low_risk_instructions") :
            teaData.pontuacao <= 7 ? t("moderate_risk_instructions") :
                t("high_risk_instructions");

        doc.setFontSize(12);
        const splitInstructions = doc.splitTextToSize(instructions, pageWidth);
        splitInstructions.forEach(line => {
            if (yPosition >= 280) {
                doc.addPage();
                yPosition = 10;
            }
            doc.text(line, 10, yPosition);
            yPosition += 7;
        });

        yPosition += 10;

        // Perguntas e Respostas no idioma selecionado
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text(t("questions_title"), 10, yPosition);
        yPosition += 10;

        perguntas.forEach((pergunta, index) => {
            if (yPosition >= 280) {
                doc.addPage();
                yPosition = 10;
            }

            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            const questionLines = doc.splitTextToSize(`${index + 1}. ${t(`${questionKey}.${index}.question`)}`, pageWidth);
            questionLines.forEach(line => {
                doc.text(line, 10, yPosition);
                yPosition += 7;
            });

            // Exemplo da pergunta, se houver
            const example = t(`${questionKey}.${index}.example`);
            if (example) {
                const exampleLines = doc.splitTextToSize(`Exemplo: ${example}`, pageWidth);
                exampleLines.forEach(line => {
                    doc.text(line, 10, yPosition);
                    yPosition += 7;
                });
            }

            // Resposta
            doc.setFont("helvetica", "normal");
            doc.text(`${t("answer_label")}: ${respostasValores[index] ? respostasValores[index].toUpperCase() : "N/A"}`, 10, yPosition);
            yPosition += 10;
        });

        // Salvar o PDF com um nome descritivo
        doc.save(`${t("checklist")}-${teaData.nome_crianca}.pdf`);
    };


    return (
        <Header>
            <InfoCard title={t("details")} icon={questionIcon} />
            {teaData ? (
                <>
                    <div className="card-container">
                        <div className="card-content">
                            <div className="left-section">
                                <h1 className="child-name">{teaData.nome_crianca}</h1>
                                <p><strong>{t("responsible_label")}:</strong> {teaData.responsavel}</p>
                                <p><strong>{t("date_label")}:</strong> {formatDate(teaData.data_nascimento)}</p>
                                <p><strong>{t("address_label")}:</strong> {teaData.endereco}</p>
                                <p><strong>{t("city_label")}:</strong> {teaData.cidade}</p>
                                <p><strong>{t("contact_number_label")}:</strong> {teaData.numero_contato}</p>
                            </div>
                            <div className="divider"></div>
                            <div className="right-section">
                                <p className="score-label">{t("score_label")}</p>
                                <h2 className="score-value">{teaData.pontuacao}</h2>
                                <p className="risk-level">
                                    {teaData.pontuacao <= 2 ? t("risk_level_low") :
                                        teaData.pontuacao <= 7 ? t("risk_level_medium") :
                                            t("risk_level_high")}<br />({teaData.pontuacao} {t("points")})
                                </p>
                            </div>
                        </div>
                    </div>



                    <div className="card-container-2">

                        <div>
                            <h2 className="details-title">{t("tea_data_title")}</h2>
                            <p className="details-subtitle">{t("tea_data_subtitle")}</p>
                        </div>
                        <div className='div-contet-res'>
                            <div className="grid-container">
                                {respostasValores.map((resposta, index) => (
                                    <div
                                        key={index}
                                        className={`grid-item ${(resposta === 'Não' || resposta === 'No') ? 'high-risk' : 'low-risk'}`}
                                        onClick={() => openModal(index)}
                                    >
                                        {index + 1}
                                    </div>
                                ))}
                            </div>


                            <div className="info-card">
                                <div>
                                    <h2 className="info-title">{t("tea_details_title")}</h2>
                                    <p className="risk-description">
                                        {teaData.pontuacao <= 2 ? t("risk_level_low") :
                                            teaData.pontuacao <= 7 ? t("risk_level_medium") :
                                                t("risk_level_high")}
                                    </p>
                                    <p>
                                        {teaData.pontuacao <= 2 ? (
                                            <p>{t("instructions_low")}</p>
                                        ) : teaData.pontuacao <= 7 ? (
                                            <p>{t("instructions_medium")}</p>
                                        ) : (
                                            <p>{t("instructions_high")}</p>
                                        )}
                                    </p>
                                </div>
                                <div className='DowPDF'>
                                    <button onClick={generatePDF}>{t("download_pdf")}</button>
                                </div>
                            </div>




                        </div>
                    </div>
                </>
            ) : (
                <p>{t("loading_data")}</p>
            )}

            {showModal && selectedQuestionIndex !== null && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h3>{t(`${questionKey}.${selectedQuestionIndex}.question`)}</h3>
                        {t(`${questionKey}.${selectedQuestionIndex}.example`) && (
                            <p><em>{t(`${questionKey}.${selectedQuestionIndex}.example`)}</em></p>
                        )}
                        <p className='resposta'>
                            <strong>{t("answer")}: {respostasValores[selectedQuestionIndex] ? respostasValores[selectedQuestionIndex].toUpperCase() : "N/A"}</strong>
                        </p>
                        <button onClick={closeModal}>{t("close")}</button>
                    </div>
                </div>
            )}

        </Header>


    );
}

export default DetalhesTEA;
