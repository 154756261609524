import React, { useState } from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

function RecSenha() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    // Função para enviar o e-mail para a API
    async function handleSendEmail() {
        if (!email) {
            Swal.fire({
                icon: 'warning',
                title: t('attention'),
                text: t('enter_email'),
            });
            return;
        }

        try {
            const response = await fetch('https://apiprojetotea.squareweb.app/solicitar-recuperacao-senha', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: t('email_sent'),
                    text: t('email_recovery_success'),
                });
            } else {
                const data = await response.json();
                Swal.fire({
                    icon: 'error',
                    title: t('error'),
                    text: data.erro || t('email_recovery_error'),
                });
            }
        } catch (error) {
            console.error("Erro:", error);
            Swal.fire({
                icon: 'error',
                title: t('error'),
                text: t('connection_error'),
            });
        }
    }

    function GoBack() {
        navigate(-1);
    }

    return (
        <div className="recuperar-senha-container">
            <h1 className="titulo">{t("forgot_password_title")}</h1>
            <p className="descricao">
                {t("forgot_password_description")}
            </p>
            <label className="label" htmlFor="email">{t("enter_email")}</label>
            <input 
                type="email" 
                id="email" 
                className="input-email-2" 
                placeholder={t("email_placeholder")} 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <button className="botao-envia-2" onClick={handleSendEmail}>{t("send")}</button>
            <button className="botao-enviar-2" onClick={GoBack}>{t("back")}</button>
        </div>
    );
}

export default RecSenha;
