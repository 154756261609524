// InstitutoAutismo.js
import React from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import processo from "../../assets/processo2.png";
import { useTranslation } from 'react-i18next';

import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/processo.png'


function ProcessoTriagem() {
    const { t } = useTranslation();

    return (
        <Header>
            <InfoCard title={t("screening_process")} icon={questionIcon} />

            <div className="tutorial-container">
                <div className="tutorial-card">
                    <div className="tutorial-header">
                        <img src={processo} alt={t("process_icon_alt")} className="sidebar-icon-3" />
                        <h2>{t("screening_process_title")}</h2>
                    </div>
                    <p className="tutorial-text">{t("screening_process_description_1")}</p>
                    <p className="tutorial-text">{t("screening_process_description_2")}</p>
                    <p className="tutorial-text">{t("screening_process_description_3")}</p>
                    <p className="tutorial-text">{t("screening_process_description_4")}</p>
                    <p className="tutorial-text">{t("screening_process_description_5")}</p>
                </div>
            </div>


        </Header>
    );
}

export default ProcessoTriagem;
