// MudarSenha.js
import React, { useState } from 'react';
import Header from '../../components/siderbar';
import { FaEye, FaEyeSlash, FaArrowLeft } from 'react-icons/fa';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

function MudarSenha() {
    const { t } = useTranslation();
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [showSenha, setShowSenha] = useState(false);
    const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);
    const navigate = useNavigate();

    const navBack = () => {
        navigate(-1);
    };

    const toggleShowSenha = () => {
        setShowSenha(!showSenha);
    };

    const toggleShowConfirmarSenha = () => {
        setShowConfirmarSenha(!showConfirmarSenha);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (senha !== confirmarSenha) {
            Swal.fire({
                icon: 'warning',
                title: t('password_mismatch_title'),
                text: t('password_mismatch_text'),
                confirmButtonText: 'OK',
                confirmButtonColor: '#4a63d0'
            });
            return;
        }

        try {
            const userId = localStorage.getItem('userId');
            const response = await fetch(`https://apiprojetotea.squareweb.app/usuario/${userId}/senha`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ nova_senha: senha })
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: t('password_change_success'),
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#4a63d0'
                }).then(() => {
                    navigate('/perfil-user');
                });
            } else {
                const data = await response.json();
                Swal.fire({
                    icon: 'error',
                    title: t('password_change_error'),
                    text: data.erro || t('generic_error_message'),
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#4a63d0'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: t('connection_error_title'),
                text: t('server_connection_error'),
                confirmButtonText: 'OK',
                confirmButtonColor: '#4a63d0'
            });
        }
    };

    return (
        <Header>
            <div className='home-container'>
                <div className="senha-container">
                    <h2>
                        <div className='goback'>
                            <p><FaArrowLeft onClick={navBack} /> {t("back")}</p>
                        </div>
                        {t("creating_new_password")}
                    </h2>
                    <p>{t("create_new_password_prompt")}</p>

                    <form onSubmit={handleSubmit}>
                        <label>{t("enter_new_password")}</label>

                        <div className="input-container">
                            <input
                                type={showSenha ? "text" : "password"}
                                placeholder={t("password_placeholder")}
                                value={senha}
                                onChange={(e) => setSenha(e.target.value)}
                            />
                            <span onClick={toggleShowSenha}>
                                {showSenha ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>

                        <div className="input-container">
                            <input
                                type={showConfirmarSenha ? "text" : "password"}
                                placeholder={t("confirm_password_placeholder")}
                                value={confirmarSenha}
                                onChange={(e) => setConfirmarSenha(e.target.value)}
                            />
                            <span onClick={toggleShowConfirmarSenha}>
                                {showConfirmarSenha ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>

                        <button type="submit" className="cadastrar-btn">{t("register_button")}</button>
                    </form>
                </div>
            </div>
        </Header>
    );
}

export default MudarSenha;
