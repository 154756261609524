import React from 'react';
import './InfoCard.css';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';



function InfoCard({ title, icon }) {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Volta para a página anterior
    };

    return (
        <div className="info-card-2">
            <div className='voltar'>
                <div className="back-div" onClick={handleBack}>
                    <FaArrowLeft className="back-icon" />
                </div>
                <span className="info-card-title">{title}</span>
            </div>
            <img src={icon} alt="icon" className="info-card-icon" />
        </div>
    );
}

export default InfoCard;
