// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
    const token = localStorage.getItem('token');

    // Se não houver token, redireciona para a página de login
    if (!token) {
        return <Navigate to="/login" />;
    }

    // Renderiza o componente protegido
    return children;
}

export default PrivateRoute;
