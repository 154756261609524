// InstitutoAutismo.js
import React, { useEffect, useState } from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { FaClock, FaArrowRight } from 'react-icons/fa';
import { getAuthHeaders } from '../../services/authHeaders';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import questionIcon from '../../assets/clock.png'

import InfoCard from '../../components/infoCard/InfoCard';

function MobileList() {
    const { t } = useTranslation();
    const [instruments, setInstruments] = useState([]);
    const [lastInstrumentDate, setLastInstrumentDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const userId = localStorage.getItem('userId');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const fetchInstruments = async () => {
            try {
                const response = await fetch(`https://apiprojetotea.squareweb.app/listar_teas?id_usuario=${userId}`, {
                    method: 'GET',
                    headers: getAuthHeaders(),
                });

                if (response.ok) {
                    const data = await response.json();
                    const reversedInstruments = data.teas.reverse();
                    setInstruments(reversedInstruments);

                    if (reversedInstruments.length > 0) {
                        setLastInstrumentDate(formatDate(reversedInstruments[0].created_at));
                    }
                } else {
                    console.error(t('fetch_instruments_error'));
                }
            } catch (error) {
                console.error(t('server_connection_error'), error);
            }
        };

        fetchInstruments();
    }, [userId, t]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredInstruments = instruments.filter(instrument =>
        instrument.nome_crianca.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Header>

            <InfoCard title={t("back")} icon={questionIcon}/>
            <div className="home-container">

                {/* Meus Instrumentos */}
                <div className="my-instruments-section">
                    <h3>{t("my_instruments")}</h3>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder={t("search_placeholder")}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <FaArrowRight className="search-icon" />
                    </div>
                    <div className="instrument-cards">
                        {filteredInstruments.map((instrument) => (
                            <Link
                                to={`/detalhe-tea?id_usuario=${userId}&id_tea=${instrument.id}`}
                                key={instrument.id}
                            >
                                <div className="instrument-card">
                                    <h4>{instrument.nome_crianca}</h4>
                                    <p>{t("responsible")}: {instrument.responsavel}</p>
                                    <p>{t("creation_date")}: {formatDate(instrument.created_at)}</p>
                                    <FaArrowRight className="card-icon" />
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </Header>
    );
}

export default MobileList;
