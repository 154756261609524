// src/services/perguntasMchat.js

const perguntas = [
    { pergunta: "Se você apontar para algum objeto no quarto, o seu filho olha para este objeto?", exemplo: "Ex.: se você apontar para um brinquedo ou animal, o seu filho olha para o brinquedo ou para o animal?" },
    { pergunta: "O seu filho gosta de subir nas coisas?", exemplo: "Ex.: móveis, brinquedos em parques ou escadas?" },
    { pergunta: "Alguma vez você se perguntou, se o seu filho pode ser surdo?", exemplo: "" },
    { pergunta: "O seu filho faz movimentos estranhos com os dedos perto dos olhos?", exemplo: "Ex.: mexe os dedos em frente aos olhos e fica olhando para os mesmos?" },
    { pergunta: "O seu filho brinca de faz de contas?", exemplo: "Ex.: faz de conta que bebe em um copo vazio, faz de conta que fala ao telefone, faz de conta que dá comida a uma boneca ou a um bichinho de pelúcia?" },
    { pergunta: "O seu filho aponta com o dedo para pedir algo ou para conseguir ajuda?", exemplo: "Ex.: aponta para um biscoito ou brinquedo fora do alcance dele?" },
    { pergunta: "O seu filho aponta com o dedo para mostrar algo interessante para você?", exemplo: "Ex.: aponta para um avião no céu ou um caminhão grande na rua?" },
    { pergunta: "O seu filho fica muito incomodado com barulhos do dia a dia?", exemplo: "Ex.: seu filho grita ou chora ao ouvir barulhos como os de liquidificador ou de música alta?" },
    { pergunta: "Quando você sorri para o seu filho, ele sorri de volta para você?", exemplo: "" },
    { pergunta: "O seu filho responde quando você o chama pelo nome?", exemplo: "Ex.: ele olha para você, fala ou emite algum som, ou para o que está fazendo quando você o chama pelo nome?" },
    { pergunta: "O seu filho se interessa por outras crianças?", exemplo: "Ex.: seu filho olha para outras crianças, sorri para elas ou se aproxima delas?" },
    { pergunta: "O seu filho traz coisas para mostrar para você ou as segura para que você as veja?", exemplo: "Ex.: para mostrar uma flor, um bichinho de pelúcia ou um caminhão de brinquedo?" },
    { pergunta: "O seu filho olha nos seus olhos quando você está falando ou brincando com ele/ela, ou vestindo a roupa dele/dela?", exemplo: "" },
    { pergunta: "Quando você vira a cabeça para olhar alguma coisa, o seu filho olha ao redor para ver o que você está olhando?", exemplo: "" },
    { pergunta: "O seu filho anda?", exemplo: "" },
    { pergunta: "O seu filho tenta fazer você olhar para ele/ela?", exemplo: "" },
    { pergunta: "O seu filho tenta imitar o que você faz?", exemplo: "Ex.: quando você dá tchau, ou bate palmas, ou joga um beijo, ele repete o que você faz?" },
    { pergunta: "Quando você vira a cabeça para olhar alguma coisa, o seu filho olha ao redor para ver o que você está olhando?", exemplo: "" },
    { pergunta: "Quando acontece algo novo, o seu filho olha para o seu rosto para ver como você se sente sobre o que aconteceu?", exemplo: "Ex.: se ele/ela ouve um barulho estranho ou vê algo engraçado, ou vê um brinquedo novo, será que ele/ela olharia para seu rosto?" },
    { pergunta: "O seu filho gosta de atividades de movimento?", exemplo: "Ex.: ser balançado ou pular em seus joelhos." }
];

export default perguntas;
