// NewSenha.js
import React, { useState, useEffect } from 'react';
import './styles.css';
import Swal from 'sweetalert2';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NewSenha() {
    const { t } = useTranslation();
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [mostrarSenha, setMostrarSenha] = useState(false);
    const [token, setToken] = useState('');
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenParam = queryParams.get('token');
        const userIdParam = queryParams.get('user_id');
        
        if (tokenParam && userIdParam) {
            setToken(tokenParam);
            setUserId(userIdParam);
        } else {
            Swal.fire({
                icon: 'error',
                title: t('error'),
                text: t('missing_token_user_id_error'),
            });
            navigate('/');
        }
    }, [location, navigate, t]);

    const toggleMostrarSenha = () => {
        setMostrarSenha(!mostrarSenha);
    };

    const handleCadastrarSenha = async () => {
        if (senha !== confirmarSenha) {
            Swal.fire({
                icon: 'error',
                title: t('error'),
                text: t('password_mismatch'),
            });
            return;
        }

        try {
            const response = await fetch('https://apiprojetotea.squareweb.app/atualizar-senha', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    user_id: userId,
                    nova_senha: senha,
                }),
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: t('success'),
                    text: t('password_updated_successfully'),
                }).then(() => {
                    navigate('/login');
                });
            } else {
                const data = await response.json();
                Swal.fire({
                    icon: 'error',
                    title: t('error'),
                    text: data.erro || t('password_update_error'),
                });
            }
        } catch (error) {
            console.error("Erro:", error);
            Swal.fire({
                icon: 'error',
                title: t('error'),
                text: t('password_update_error'),
            });
        }
    };

    return (
        <div className="recuperar-senha-container">
            <h1 className="titulo">{t("creating_new_password")}</h1>
            <p className="descricao">
                {t("create_new_password_prompt")}
            </p>
            <label className="label" htmlFor="senha">{t("enter_new_password")}</label>
            <div className="input-senha-container">
                <input 
                    type={mostrarSenha ? 'text' : 'password'} 
                    id="senha" 
                    className="input-email" 
                    placeholder={t("new_password_placeholder")} 
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                />
                <span 
                    className="toggle-visualizar-senha" 
                    onClick={toggleMostrarSenha}
                >
                    {mostrarSenha ? <FaEyeSlash /> : <FaEye />}
                </span>
            </div>
            <div className="input-senha-container">
                <input 
                    type={mostrarSenha ? 'text' : 'password'} 
                    id="confirmar-senha" 
                    className="input-email" 
                    placeholder={t("confirm_password_placeholder")} 
                    value={confirmarSenha}
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                />
                <span 
                    className="toggle-visualizar-senha" 
                    onClick={toggleMostrarSenha}
                >
                    {mostrarSenha ? <FaEyeSlash /> : <FaEye />}
                </span>
            </div>
            <button className="botao-enviar" onClick={handleCadastrarSenha}>{t("register_new_password")}</button>
        </div>
    );
}

export default NewSenha;
