import React from 'react';
import './styles.css';
import { FaArrowRight } from 'react-icons/fa';
import Logo from "../../assets/logo.png";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/seletor';

function InstitutoAutismo() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigate = () => {
        navigate('/login');
    };

    return (
        <div className="container">
            <div className="content">
                <img
                    src={Logo}
                    alt={t("logo_alt_text")}
                    className="logo"
                />
                <h1 className="title">{t("title")}</h1>
                <p className="description">
                    {t("checklist")}
                </p>
                <LanguageSelector />
                <div className='position-button'>
                    <button className="button" onClick={handleNavigate}>
                        {t("login")} <FaArrowRight className="arrow-icon" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InstitutoAutismo;
