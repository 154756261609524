// Cadastro.js
import React, { useState } from 'react';
import './styles.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Cadastro() {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [isProfessional, setIsProfessional] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [formData, setFormData] = useState({
        nomeCompleto: '',
        email: '',
        senha: '',
        confirmacaoSenha: '',
        dataNascimento: '',
        profissao: ''
    });

    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Verificação de senha e confirmação em tempo real
        if (name === 'confirmacaoSenha' || name === 'senha') {
            validatePassword(value, name);
        }
    };

    const validatePassword = (value, fieldName) => {
        const senha = fieldName === 'senha' ? value : formData.senha;
        const confirmacaoSenha = fieldName === 'confirmacaoSenha' ? value : formData.confirmacaoSenha;

        if (confirmacaoSenha && senha !== confirmacaoSenha) {
            setPasswordError(t('password_mismatch'));
        } else {
            setPasswordError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.senha !== formData.confirmacaoSenha) {
            alert(t('password_mismatch'));
            return;
        }

        const dataNascimentoFormatada = formData.dataNascimento
            ? new Date(formData.dataNascimento).toISOString().split('T')[0]
            : '';

        const data = {
            nome_completo: formData.nomeCompleto,
            email: formData.email,
            senha: formData.senha,
            data_nascimento: dataNascimentoFormatada,
            profissao: formData.profissao,
            profissional_saude: isProfessional === 'sim' ? 'sim' : 'não'
        };

        try {
            const response = await fetch('https://apiprojetotea.squareweb.app/cadastro', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if (response.ok) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: t('registration_success'),
                    showConfirmButton: false,
                    timer: 2500
                });

                setTimeout(() => {
                    navigate('/login');
                }, 1500);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t('error'),
                    text: result.erro || t('registration_error')
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: t('error'),
                text: t('connection_error')
            });
        }
    };

    return (
        <div className="login-container">
            <div className='contetn-info-texts'>
                <h1 className="welcome-title">{t("create_account_title")}</h1>
                <p className="subtitle">
                    {t("create_account_description")}
                </p>
            </div>

            <form className="login-form" onSubmit={handleSubmit}>
                <div className="password-field">
                    <input
                        name="nomeCompleto"
                        placeholder={t("full_name")}
                        type="text"
                        className="input-field"
                        value={formData.nomeCompleto}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="password-field">
                    <input
                        name="email"
                        placeholder={t("email_placeholder")}
                        type="email"
                        className="input-field"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="password-field">
                    <input
                        name="senha"
                        type={showPassword ? "text" : "password"}
                        className="input-field"
                        placeholder={t("password_placeholder")}
                        value={formData.senha}
                        onChange={handleInputChange}
                    />
                    <span onClick={togglePasswordVisibility} className="toggle-password">
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                </div>
                <div className="password-field">
                    <input
                        name="confirmacaoSenha"
                        type={showPassword ? "text" : "password"}
                        className={`input-field ${passwordError ? 'input-error' : ''}`}
                        placeholder={t("confirm_password")}
                        value={formData.confirmacaoSenha}
                        onChange={handleInputChange}
                    />
                    <span onClick={togglePasswordVisibility} className="toggle-password">
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                    {passwordError && <p className="error-message">{passwordError}</p>}
                </div>

                <div className="password-field">
                    <input
                        name="dataNascimento"
                        placeholder={t("dob_placeholder")}
                        type="date"
                        className="input-field"
                        value={formData.dataNascimento}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="password-field">
                    <input
                        name="profissao"
                        placeholder={t("profession")}
                        type="text"
                        className="input-field"
                        value={formData.profissao}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="radio-group">
                    <label className="radio-label">{t("health_professional")}</label>
                    <div className="radio-options">
                        <label>
                            <input
                                type="radio"
                                name="professional"
                                value="sim"
                                checked={isProfessional === 'sim'}
                                onChange={() => setIsProfessional('sim')}
                            />
                            {t("yes")}
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="professional"
                                value="nao"
                                checked={isProfessional === 'nao'}
                                onChange={() => setIsProfessional('nao')}
                            />
                            {t("no")}
                        </label>
                    </div>
                </div>

                <button type="submit" className="login-button">{t("register")}</button>
            </form>
        </div>
    );
}

export default Cadastro;
