// CadastroMchatRf.js
import React, { useState } from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { FaArrowRight } from 'react-icons/fa';
import PerguntasMchatRf from '../../components/mchatrf/PerguntasMchatRf';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/m-chat-rf.png'


function CadastroMchatRf() {
    const { t } = useTranslation();
    const [showPerguntas, setShowPerguntas] = useState(false);
    const [formData, setFormData] = useState({
        nomeCrianca: '',
        dataNascimento: '',
        responsavel: '',
        numeroContato: '',
        endereco: '',
        cidade: ''
    });

    const handleContinue = () => {
        if (Object.values(formData).some(field => field.trim() === '')) {
            Swal.fire({
                icon: 'warning',
                title: t('required_fields_title'),
                text: t('required_fields_text'),
                confirmButtonText: 'OK',
                confirmButtonColor: '#4a63d0'
            });
        } else {
            setShowPerguntas(true);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <Header>
            <InfoCard title="M-CHAT-R/F" icon={questionIcon} />
            {!showPerguntas ? (


                <>

                    <div className="mchat-container">
                        <div className="mchat-header">

                            <p>{t('mchat_description')}</p>
                        </div>

                        <div className="mchat-form">
                            <input
                                type="text"
                                name="nomeCrianca"
                                placeholder={t("child_name")}
                                onChange={handleChange}
                            />
                            <input
                                type="date"
                                name="dataNascimento"
                                placeholder={t("dob_placeholder")}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="responsavel"
                                placeholder={t("responsible")}
                                onChange={handleChange}
                            />
                            <input
                                type="tel"
                                name="numeroContato"
                                placeholder={t("contact_number")}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="endereco"
                                placeholder={t("address")}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="cidade"
                                placeholder={t("city")}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='button-org-info-cadastro-m-chat'>
                            <button className="continue-btn" onClick={handleContinue}>
                                {t("continue")} <FaArrowRight />
                            </button>
                        </div>
                    </div>

            
                </>
            ) : (
                <PerguntasMchatRf formData={formData} />
            )}
        </Header>
    );
}

export default CadastroMchatRf;
