import React, { useState, useEffect } from 'react';
import { FaArrowRight, FaArrowLeft, FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import NivelTEA from '../nivel_tea';
import { useTranslation } from 'react-i18next';

import './styles.css';

const PerguntasMchatRf = ({ formData }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [pagina, setPagina] = useState(1);
    const [respostas, setRespostas] = useState({});
    const [showResumo, setShowResumo] = useState(false);
    const [pontuacao, setPontuacao] = useState(0);
    const [perguntas, setPerguntas] = useState([]);

    const perguntasPorPagina = 6;
    const totalPaginas = Math.ceil(perguntas.length / perguntasPorPagina);

    // Atualiza as perguntas com base no idioma
    useEffect(() => {
        let perguntasKey;
        switch (i18n.language) {
            case 'pt_br':
                perguntasKey = "questions_br";
                break;
            case 'pt_pt':
                perguntasKey = "questions_pt";
                break;
            case 'en':
                perguntasKey = "questions_en";
                break;
            default:
                perguntasKey = "questions_br"; // Idioma padrão
                break;
        }

        const loadedPerguntas = t(perguntasKey, { returnObjects: true });
        setPerguntas(loadedPerguntas);
    }, [i18n.language, t]);

    const handleResposta = (index, resposta) => {
        const perguntaIndex = (pagina - 1) * perguntasPorPagina + index;

        setRespostas((prevRespostas) => ({
            ...prevRespostas,
            [perguntaIndex]: resposta
        }));

        setPontuacao((prevPontuacao) => {
            if ((perguntaIndex === 1 || perguntaIndex === 4 || perguntaIndex === 11) && resposta === 'sim') {
                return prevPontuacao + 1;
            } else if (!([1, 4, 11].includes(perguntaIndex)) && resposta === 'nao') {
                return prevPontuacao + 1;
            } else {
                return prevPontuacao;
            }
        });
    };

    const handleContinuar = () => {
        const perguntasPagina = perguntas.slice(
            (pagina - 1) * perguntasPorPagina,
            pagina * perguntasPorPagina
        );

        const todasRespondidas = perguntasPagina.every((_, index) =>
            respostas[(pagina - 1) * perguntasPorPagina + index] !== undefined
        );

        if (!todasRespondidas) {
            Swal.fire({
                title: t('attention'),
                text: t('answer_all_questions'),
                icon: 'warning',
                confirmButtonText: 'OK'
            });
            return;
        }

        if (pagina < totalPaginas) {
            setPagina(pagina + 1);
        } else {
            setShowResumo(true);
        }
    };

    const handleVoltar = () => {
        if (pagina === 1) {
            navigate(-1);
        } else {
            setPagina(pagina - 1);
        }
    };

    const perguntasPagina = perguntas.slice(
        (pagina - 1) * perguntasPorPagina,
        pagina * perguntasPorPagina
    );

    const respostasArray = perguntas.map((_, index) => respostas[index] || t('not_answered'));

    return (
        <div className='home-container'>
            {!showResumo ? (
                <>
                    <div className="mchat-header">
                        <h2>M-CHAT-R/F</h2>
                        <p>{t("mchat_description")}</p>
                    </div>

                    <div className="perguntas-container">
                        {perguntasPagina.map((item, index) => {
                            const perguntaNumero = (pagina - 1) * perguntasPorPagina + index + 1;
                            return (
                                <div className="pergunta-card" key={index}>
                                    <p className="pergunta-text">
                                        <strong>{perguntaNumero}. </strong> {/* Número da pergunta */}
                                        {item.question}
                                    </p>
                                    {item.example && <p className="exemplo-text">{item.example}</p>}
                                    <div className="resposta-buttons">
                                        <button
                                            className={`resposta-sim ${respostas[(pagina - 1) * perguntasPorPagina + index] === 'sim' ? 'selected' : ''}`}
                                            onClick={() => handleResposta(index, 'sim')}
                                        >
                                            {t("yes")}
                                        </button>
                                        <button
                                            className={`resposta-nao ${respostas[(pagina - 1) * perguntasPorPagina + index] === 'nao' ? 'selected' : ''}`}
                                            onClick={() => handleResposta(index, 'nao')}
                                        >
                                            {t("no")}
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="button-container">
                        {pagina > 1 && (
                            <button className="voltar-btn" onClick={handleVoltar}>
                                <FaArrowLeft /> {t("back")}
                            </button>
                        )}
                        <button className="continuar-btn" onClick={handleContinuar}>
                            {pagina < totalPaginas ? (
                                <>{t("continue")} <FaArrowRight /></>
                            ) : (
                                <>{t("finish")} <FaCheckCircle /></>
                            )}
                        </button>
                    </div>
                </>
            ) : (
                <div className="resumo-container">
                    <NivelTEA formData={formData} pontuacao={pontuacao} respostas={respostasArray} />
                </div>
            )}
        </div>
    );
};

export default PerguntasMchatRf;
