// services/authHeaders.js

export const getAuthHeaders = () => {
    const token = localStorage.getItem('token'); // Obtém o token do localStorage

    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};
