// InstitutoAutismo.js
import React, { useEffect, useState } from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { FaClock, FaArrowRight, FaCalendar } from 'react-icons/fa';
import { getAuthHeaders } from '../../services/authHeaders';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function HomePage() {
    const { t } = useTranslation();
    const location = useLocation();
    const [instruments, setInstruments] = useState([]);
    const [lastInstrumentDate, setLastInstrumentDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const userId = localStorage.getItem('userId');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const fetchInstruments = async () => {
            try {
                const response = await fetch(`https://apiprojetotea.squareweb.app/listar_teas?id_usuario=${userId}`, {
                    method: 'GET',
                    headers: getAuthHeaders(),
                });

                if (response.ok) {
                    const data = await response.json();
                    const reversedInstruments = data.teas.reverse();
                    setInstruments(reversedInstruments);

                    if (reversedInstruments.length > 0) {
                        setLastInstrumentDate(formatDate(reversedInstruments[0].created_at));
                    }
                } else {
                    console.error(t('fetch_instruments_error'));
                }
            } catch (error) {
                console.error(t('server_connection_error'), error);
            }
        };

        fetchInstruments();
    }, [userId, t]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredInstruments = instruments.filter(instrument =>
        instrument.nome_crianca.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        // Ajusta a altura da `.main-content` com base na rota e apenas para dispositivos móveis
        const mainContent = document.querySelector('.main-content');

        const handleResize = () => {
            if (window.innerWidth <= 768) { // Verifica se está em um dispositivo móvel
                if (location.pathname === '/home-page') {
                    mainContent.style.height = '50vh';
                } else {
                    mainContent.style.height = '100vh';
                }
            } else {
                // Reseta a altura se não for mobile
                mainContent.style.height = '100vh';
            }
        };

        // Adiciona o evento de resize para detectar mudanças de tamanho de janela
        window.addEventListener('resize', handleResize);

        // Executa a verificação inicial
        handleResize();

        // Remove o evento ao desmontar o componente
        return () => window.removeEventListener('resize', handleResize);
    }, [location.pathname]);


    return (
        <Header>
            <div className="home-container">
                {/* Banner Superior */}
                <div className="last-instrument-banner">
                    <div className="banner-content">
                        <h2>{t("last_instrument_created")}</h2>
                        <div className='content-info-date-btn'>
                            <h1>{lastInstrumentDate || t("no_date_available")}</h1>

                            {instruments.length > 0 && (
                                <Link to={`/detalhe-tea?id_usuario=${userId}&id_tea=${instruments[0].id}`}>
                                    <button className="view-now-btn">{t("view_now")} <FaArrowRight /></button>
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="clock-info-container">
                        <div className="clock-info">
                            <FaClock className="banner-icon" />
                        </div>
                    </div>
                </div>

                {/* Meus Instrumentos */}
                <div className="my-instruments-section mobi">
                    <h3>{t("my_instruments")}</h3>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder={t("search_placeholder")}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <FaArrowRight className="search-icon" />
                    </div>
                    <div className="instrument-cards ">
                        {filteredInstruments.map((instrument) => (
                            <Link
                                to={`/detalhe-tea?id_usuario=${userId}&id_tea=${instrument.id}`}
                                key={instrument.id}
                            >
                                <div className="instrument-card">
                                    <h4>{instrument.nome_crianca}</h4>
                                    <p>{t("responsible")}: {instrument.responsavel}</p>
                                    <p>{t("creation_date")}: {formatDate(instrument.created_at)}</p>
                                    <FaArrowRight className="card-icon" />
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>

                <div className="ContentMobi">
                    <Link to={`/lista-teas`}>
                        <div className="banner-content">
                            <div className='content-info-date-btn'>
                                <h2>{t("my_instruments")}</h2>
                            </div>

                            <Link to={`/lista-teas`}>
                                <button className="view-now-btn">{t("view_now")} <FaArrowRight /></button>
                            </Link>
                        </div>
                        <div className="clock-info-container">
                            <div className="clock-info-2">
                                <FaCalendar className="banner-icon" />
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </Header >
    );
}

export default HomePage;
