// Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ titulo, texto, subtitulo, subtexto, cor, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className={`modal-content ${cor}`}>
                <button className="modal-close" onClick={onClose}>✕</button>
                <h2>{titulo}</h2>
                <p>{texto}</p>
                <h3>{subtitulo}</h3>
                <p>{subtexto}</p>
            </div>
        </div>
    );
};

function Modal2({ onClose, title, children }) {
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>{title}</h2>
                <div className="modal-body">{children}</div>

                <div className='modal-closeBTN'>

                    <button className="close-btn" onClick={onClose}>Close</button>
                </div>

            </div>
        </div>
    );
}

export default Modal;
export { Modal2 };
