// NivelTEA.js
import React from 'react';
import './NivelTEA.css';
import { FaCheckCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { getAuthHeaders } from '../../services/authHeaders';
import { useTranslation } from 'react-i18next';

const NivelTEA = ({ pontuacao, formData, respostas }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    let nivel, risco, instrucoes, corFundoClasse, corCirculo;

    if (pontuacao <= 2) {
        nivel = t("low_risk_level");
        risco = t("points_range_0_2");
        instrucoes = (
            <>
                {t("low_risk_instructions")}
            </>
        );
        corFundoClasse = "baixo-risco";
        corCirculo = "pontuacao-display-baixo";
    } else if (pontuacao <= 7) {
        nivel = t("moderate_risk_level");
        risco = t("points_range_3_7");
        instrucoes = (
            <>
                {t("moderate_risk_instructions")}
            </>
        );
        corFundoClasse = "moderado-risco";
        corCirculo = "pontuacao-display-medio";
    } else {
        nivel = t("high_risk_level");
        risco = t("points_range_8_20");
        instrucoes = (
            <>
                {t("high_risk_instructions")}
            </>
        );
        corFundoClasse = "alto-risco";
        corCirculo = "pontuacao-display-alto";
    }

    async function LogDados() {
        const idUsuario = localStorage.getItem("userId");

        const respostasFormatadas = respostas.map((resposta, index) => ({
            [`pergunta${index + 1}`]: resposta
        }));

        const dados = {
            id_usuario: idUsuario,
            nome_crianca: formData.nomeCrianca,
            responsavel: formData.responsavel,
            data_nascimento: formData.dataNascimento,
            numero_contato: formData.numeroContato,
            endereco: formData.endereco,
            cidade: formData.cidade,
            pontuacao: pontuacao,
            respostas: respostasFormatadas
        };

        try {
            const response = await fetch('https://apiprojetotea.squareweb.app/cadastrar_tea', {
                method: 'POST',
                headers: getAuthHeaders(),
                body: JSON.stringify(dados)
            });

            if (response.ok) {
                Swal.fire({
                    title: t('success_title'),
                    text: t('data_saved_success'),
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    navigate('/home-page');
                });
            } else {
                Swal.fire({
                    title: t('error_title'),
                    text: t('data_save_failure'),
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error(t("save_error_log"), error);
            Swal.fire({
                title: t('error_title'),
                text: t('server_connection_error'),
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }

    return (
        <div className="nivel-tea-container">
            <div className={`nivel-tea-card ${corFundoClasse}`}>
                <div className="org-info-tea">
                    <div>
                        <h2>{t("score_reached")}</h2>
                        <p className="nivel-risco">{nivel}</p>
                        <p className="pontuacao-risco">{risco}</p>
                    </div>

                    <div className={`pontuacao-display ${corCirculo}`}>
                        <span className="pontuacao-valor">{pontuacao}</span>
                    </div>
                </div>

                <h3>{t("what_to_do")}</h3>
                <p className="nivel-instrucoes">{instrucoes}</p>
            </div>
            <button className="salvar-btn" onClick={LogDados}>
                {t("save_button")} <FaCheckCircle />
            </button>
        </div>
    );
};

export default NivelTEA;
